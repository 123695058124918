import React from "react"
import { useSiteMetadata } from "~hooks/use-site-metadata"
import { PageWrapper, SEO, Link } from "~components"
import TermsConditionSection from "~sections/utility/TermsCondition"
import FooterSimple from "~components/FooterSimple"

const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky position-relative site-header--menu-sep",
  containerFluid: false,
  darkLogo: true,
  buttonBlock: (
    <Link
      title={"Gift Cards"}
      className="btn btn-outline-secondary rounded-pill py-3 ms-auto d-none d-xs-inline-flex"
      link="https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ==">
      <span>
        <i className="fa fa-gift"></i>
        {"  "}Gift Cards
      </span>
    </Link>
  ),
}

export default function TermsAndConditions({ data }) {
  const siteMetadata = useSiteMetadata()
  // const [siteMetadata, setSiteMetadata] = React.useState(useSiteMetadata())

  React.useEffect(() => {
    // console.log(siteMetadata)
  }, [])

  // console.log({ data })
  // const rightImage = getImage(data?.rightImage?.edges[0]?.node)
  // const leftImage = getImage(data?.leftImage?.edges[0]?.node)
  // const yelpReviews = data?.yelpReviews?.edges
  const allMDX = data?.allMDX

  return (
    // <PageWrapper innerPage={true}>
    <PageWrapper headerConfig={header}>
      <SEO
        title={`${siteMetadata.title}`}
        description={`${siteMetadata.description}`}
        type={`${siteMetadata.type}`}
        location="/"
        indexed="false"
      />

      <TermsConditionSection />
      <FooterSimple />
    </PageWrapper>
  )
}
